import { FC } from "react";
import Image from "next/image";
import styles from "./PackageCard.module.css";
import { FlexCol, FlexRow } from "ui-kit/Layout/Flex";
import { Typography } from "ui-kit/Typography";
import Gutter from "ui-kit/Gutter";
import { useWebTranslation } from "translations";
import { Package } from "components/ContentfulRenderer/ContentfulPackages/types";
import classNames from "classnames";
import { formatCurrency } from "utils/utils";
import PackageCardTab from "./PackageCardTab";
import { AmountToPay } from "utils/insurance/getAmountToPay";

interface PackageCardProps {
  pack: Package;
  amountToPay?: AmountToPay;
  rounded?: boolean;
  showMarketing?: boolean;
}

const PackageCard: FC<PackageCardProps> = ({
  pack,
  amountToPay,
  rounded,
  showMarketing,
}) => {
  const { t } = useWebTranslation();

  const { title, subTitle, img, color, popular, popularColor } = pack;

  return (
    <FlexCol justifyContent="flex-end">
      {popular && (
        <PackageCardTab text={t("global.most_popular")} color={popularColor} />
      )}
      <FlexRow
        className={classNames(
          styles.container,
          rounded && styles.rounded,
          popular && styles.popular
        )}
        alignItems="flex-start"
        style={{
          backgroundColor: color,
        }}
      >
        <Image
          src={img}
          alt={t(title)}
          className={styles.image}
          width={42}
          height={50}
        />

        <FlexCol className={styles.text} justifyContent="center">
          <FlexRow justifyContent="space-between" alignItems="center">
            <FlexRow
              justifyContent="space-between"
              style={{ flexDirection: "column" }}
            >
              <Typography variant="h4">{t(title)}</Typography>
              {showMarketing && pack.marketing?.fromPrice ? (
                <Typography variant="body2">
                  {t(pack.marketing?.fromPrice)}
                </Typography>
              ) : null}
            </FlexRow>
            {amountToPay ? (
              <Typography variant="h4" style={{ marginInlineStart: 8 }}>
                {formatCurrency(amountToPay.old ?? amountToPay.new)}
              </Typography>
            ) : null}
          </FlexRow>
          <Gutter size={0.5} />
          <Typography variant="body3" style={{ margin: 0 }}>
            {showMarketing && pack.marketing?.subTitle
              ? t(pack.marketing.subTitle)
              : t(subTitle)}
          </Typography>
        </FlexCol>
      </FlexRow>
    </FlexCol>
  );
};

export default PackageCard;
